
.bannerStyle {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    padding: 1rem;
    z-index: 999;
}
  
.buttonStyle {
    margin-left: 10px;
    padding: 0.5rm, 1rem;
    background-color: var(--cream);
    color: var(--black);
    border: none;
    cursor: pointer;
    z-index: 999;
}

.generalText {
    margin-right: 15px;
}
  