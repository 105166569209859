.button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none !important;
  background-color: var(--cream);
  color: var(--white);
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 20px 60px 16px;
  transition: all 0.3s linear;
  overflow: hidden;
  letter-spacing: 0.1em;
  border-radius: var(--border-radius);
  z-index: 1;
}

.button:hover {
  color: var(--red);
  background-color: transparent;
}

.button::before {
  position: absolute;
  content: "";
  background-color: var(--dark-green);
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  z-index: -1;
  transition: all 500ms ease;
}

.button::after {
  position: absolute;
  content: "";
  background-color: var(--dark-green);
  width: 0%;
  height: 100%;
  right: 0px;
  top: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: -1;
  transition: all 500ms ease;
}

.button:hover::before,
.button:hover::after {
  width: 50%;
}

.slider__content {
  position: relative;
  display: block;
}
  
.header {
    margin: 0;
    color: var(--white);
    font-weight: 900;
    font-size: 60px;
}

.header__subtitle {
    margin: 0;
    margin-bottom: 35px;
    color: var(--light-grey);
    font-weight: 600;
    font-size: 46px;
    line-height: 42px;
}

.header__animation {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition: transform 2000ms ease, opacity 2000ms ease;
}

.subtitle__animation {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition-delay: 1000ms;
  transition: transform 2000ms ease, opacity 2000ms ease;
}

.button__animation {
    opacity: 0;
    transform: translateY(100px);
    transition-delay: 2500ms;
    transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease;
}

@media screen and (max-width: 500px) {
  .header__subtitle  {
    font-size: 30px;
    line-height: 42px;
  }

  .header {
    font-size: 55px!important;
  }

  .button {
    padding: 18px 45px 16px;
  }
}
