.p {
	margin: 0;
}

.p14 {
	font-size: 14px;
	line-height: 24px;
}

.p16 {
	font-size: 16px;
	line-height: 24px;
}

.p18 {
	font-size: 18px;
	line-height: 29px;
	margin-bottom: 14px;
}

.p24 {
	font-size: 24px;
	line-height: 29px;
	margin-bottom: 14px;
}

.italic {
	font-size: 14px;
	color: var(--light-grey);
	margin-bottom: 5px;
}