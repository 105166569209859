.filtering .filter {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;

}
.filtering .filter.box {
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  display: inline-block;
}
.filtering span {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

.filtering span.active {
  color: var(--red);
}
