.upper{
  text-transform: uppercase;
}

.suptitle{
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  color: var(--black);
  font-weight: 600;
}

.titleBox {
  min-height: 72px;
}

.paragraph{
  font-size: 14px;
  text-align: justify;
  line-height: 26px;
}

@media screen and (max-width: 768px) {
  .suptitle {
    font-size: 12px;
  }
}
.suptitle:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(50% - 30px);
  height: 5px;
  width: 60px;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 3;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.advantage:before {
  content: "";
  width: 100%;
  height: 5px;
  background-color: transparent;
  border: 3px solid rgb(229, 229, 229);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgb(229, 229, 229) 1px, rgb(229, 229, 229) 3px) 3;
  position: absolute;
  z-index: -1;
  top: 100px;
  left: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.advantage .icon {
  background-color: rgb(255, 255, 255);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.advantage:hover:before {
  background-color: transparent;
  border: 3px solid var(--dark-green);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, var(--dark-green) 1px, var(--dark-green) 3px) 3;
}

.advantage:hover .icon {
  border-color: var(--dark-green);
}

.icon {
  display: inline-flex;
  padding: 15px;
}

.icon.icon__border {
  border: 5px solid rgb(229, 229, 229);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgb(229, 229, 229) 1px, rgb(229, 229, 229) 3px) 5;
}

.icon.icon__border {
  border: 5px solid rgb(229, 229, 229);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgb(229, 229, 229) 1px, rgb(229, 229, 229) 3px) 5;
}

.icon__box {
  display: block;
  position: relative;
}

.icon__box .icon img {
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.icon__box:hover .icon img {
  transform: scale(1.1);
}

.icon__box:hover .icon.icon__border {
  border: 5px solid var(--dark-green);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, var(--dark-green) 1px, var(--dark-green) 3px) 5;
}

