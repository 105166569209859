.button {
	display: inline-block;

	box-sizing: border-box;
	padding: 10px 30px;
	height: auto;

	cursor: pointer;
	transform: all 0.2s;
	text-align: center;

    box-shadow: none;
	border: none;

	background-color: var(--cream);
	border: solid 2px var(--cream);
	color: var(--black);
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0px;
    font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	max-width: 300px;
	min-height: 55px;
}

.button:hover {
	color: var(--cream);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 20%);
    border-color: var(--white);
    background: var(--white);
}