.thumb{
  position: relative;
  width: 80%;
  /*margin: 0 auto;*/
  img{
    height: auto;
    max-width: 80%;
    vertical-align: middle;
  }
  &::before{
    content: '';
    width: 80%;
    height: 100%;
    position: absolute;
    bottom: -10%;
    right: 10%;
    border: 10px solid var(--light-grey);
  }
}

.story_list{
  list-style: none;
  padding: 0;
  li{
    font-size: 1.1rem;
    font-weight: 400;
    width: max-content;
    padding: 4px 0;
    margin-bottom: 10px;
  }
}

.heading{
  font-weight: 600;
  color: var(--black);
  margin-bottom: 1rem;
}

.section{
  padding: 75px 0;
}

.icon{
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  color: var(--cream);
  margin-right: 15px;
}

.content{
  line-height: 1.6rem;
  margin-bottom: 1rem;
}

.flex__center{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}