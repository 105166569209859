.upper{
  text-transform: uppercase;
}

.suptitle {
  font-family: "Sora", sans-serif;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .suptitle {
    font-size: 12px;
  }
}
.suptitle:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 60px;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 3;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.divider {
  height: 5px;
  width: 100%;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 3;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.list li, .mil-text ul li {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.list li:before, .mil-text ul li:before {
  content: "";
  display: inline;
  width: 6px;
  height: 6px;
  border: solid 1px rgb(0, 0, 0);
  background-color: var(--cream);
  transform: rotate(45deg);
  margin-right: 15px;
}
.list li .mil-additional-text, .mil-text ul li .mil-additional-text {
  opacity: 0.4;
}

.dark{

}