.h1 {
	margin: 0;
	font-size: 26px;
	font-weight: 500;
	line-height: 35px;
	position: relative;
	color: var(--cream);
}

.h2 {
	margin: 0;
	font-size: 34px;
	font-weight: 700;
	line-height: 1.3em;
	display: inline-block;
    text-transform: capitalize;
	color: var(--cream);
	position: relative;
	margin-bottom: 30px;
}

.h3 {
	margin: 0;
	font-size: 28px;
	font-weight: normal;
	line-height: 27px;
	color: var(--cream);
}

.left {
	text-align: left;
}

.left::before {
	position: absolute;
    content: "";
    left: -65px;
    height: 2px;
    top: 45%;
    width: 50px;
    background-color: var(--cream);
}