.footer__wrapper{
    padding: 70px 0 0 0;
    background: var(--dark-green);
}

.footer__wrapper h5{
    color:  var(--white);
    margin: 0 0 20px 0;
}

.copyright {
    margin-top: 70px;
    padding: 30px 0;
    color:  var(--light-grey);
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 767px) {
    .footer__wrapper .logo, .explore, .contact {
      margin-bottom: 175px;
    }
    .copyright {
      margin-top: 50px;
      font-size: 14px;
    }
  }

  .explore ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .explore ul li {
    position: relative;
    margin-top: 10px;
    padding-left: 20px;
    color: var(--white);
    cursor: pointer;
  }
  
  .explore ul li:last-child {
    margin-bottom: 0;
  }

  .explore ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 5px;
    height: 5px;
    background: var(--white);
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  .explore ul em {
    cursor: pointer;
    display: block;
    color: var(--cream);
    font-style: normal;
    padding-left: 26px;
  }
  
  .logo {
    max-width: 100%;
    width: auto;
    max-height: 40px;
  }

  .logo svg {
    max-width: 100%;
    width: auto;
    max-height: 80px;
  } 

.contact ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .contact ul li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 50px;
    color: #fff;
  }
  
  .contact ul li:last-child {
    margin-bottom: 0;
  }
  
  .icon {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 28px;
    color: var(--cream);
  }
  
  .contact ul li em {
    display: block;
    color: var(--cream);
    font-style: normal;
    font-weight: 500;
  }

  .gallery ul {
    margin: 0 -4px;
    padding: 0;
    list-style: none;
    font-size: 0;
  }
  
  .gallery ul li {
    padding: 4px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 33.333%;
  }
  
  .gallery ul li img {
    position: relative;
    width: 100%;
    height: 80px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
  }

  .image__hover {
    position: relative;
    overflow: hidden;
    display: block;
    font-size: 0;
    img {
      transform: scale(1);
      transition: all 0.7s cubic-bezier(0.3, 0, 0.3, 1);
      -webkit-transition: all 0.7s cubic-bezier(0.3, 0, 0.3, 1);
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  

  