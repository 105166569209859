.footer__social {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-top: 1px solid rgba(var(--white), 0.1);
    padding-top: 30px;
    margin-top: 19px;
  }
  
  .footer__social a {
    position: relative;
    height: 45px;
    width: 45px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    color: #a5a3b5;
    font-size: 15px;
    background-color: var(--light-grey);
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 1;
  }
  
  .footer__social a:hover {
    color: var(--cream);
    background-color: transparent;
  }
  
  .footer__social a:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: var(--cream);
    border-radius: 50%;
    transition: transform 500ms ease;
    transform: scale(0, 1);
    transform-origin: left center;
    z-index: -1;
  }
  
  .footer__social a:hover:before {
    transform: scale(1, 1);
    transform-origin: right center;
  }
  
  .footer__social a + a {
    margin-left: 10px;
  }

  .footer__social img{
    width: 25px;
  }