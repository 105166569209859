.header {
  position: relative;
  display: block;
  padding: 210px 0 118px;
  background-color: var(--black);
  overflow: hidden;
  z-index: 1;
}

.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--black);
  background-blend-mode: luminosity;
  opacity: 0.4;
  z-index: -1;
}

.header__inner {
  position: relative;
  display: block;
  z-index: 15;
}

.header__inner h2 {
  font-size: 55px;
  color: var(--white);
  line-height: 65px;
  font-weight: 900;
  margin-top: 18px;
}

.breadcrumb li {
  position: relative;
  display: inline-block;
  color: var(--white);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 22px;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media screen and (max-width: 600px) {
  .header__inner h2 {
    font-size: 45px;
    line-height: 55px;
    font-weight: 900;
    margin-top: 18px;
  }

  .breadcrumb li {
    font-size: 10px;
  }
}

.breadcrumb {
  position: relative;
  display: inline-block;
  padding: 4px 20px 2px;
  line-height: 22px;
  z-index: 1;
}

.breadcrumb:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--white);
  opacity: 0.2;
  z-index: -1;
  border-radius: 30px;
}



.breadcrumb li + li {
  margin-left: 4px;
}

.breadcrumb li a {
  position: relative;
  display: inline-block;
  color: var(--white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.breadcrumb li:hover a {
  color: var(--red);
}