.call__number {
    margin-left: 20px;
}

.call_number h5 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
}

.call_number h5 a {
    color: var(--cream);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.call_number a:hover {
    color: var(--red);
}

.call_number p {
    font-size: 14px;
    margin: 0;
    line-height: 26px;
    color: var(--white);
    font-weight: 500;
    opacity: 0.6;
}

.wrapper__call {
    position: relative;
    display: flex;
    align-items: center;
  }

.call {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: rgba(var(--white-rgb), 0.1);
  border-radius: 50%;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media screen and (max-width: 600px) {
  .call__number {
    display: none;
  }
}

.call__number h5{
  color: var(--cream);
  font-size: 18px;
}

.call:hover {
  background-color: var(--white);
}

.call__icon span {
  font-size: 20px;
  color: var(--white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.call__icon:hover span {
  color: var(--red);
}

.wrapper__logo {
  position: relative;
  float: left;
  padding-top: 24px;
  padding-bottom: 12px;
  margin-right: 45px;
}
  
.wrapper__logo:before {
  position: absolute;
  top: 25px;
  bottom: 6px;
  right: -75px;
  content: "";
  background-color: rgba(var(--white-rgb), 0.15);
  width: 1px;
  margin-right: 36px;
}

@media screen and (max-width: 400px) {
  .wrapper__logo:before {
    right: -75px;
  }

  .wrapper__logo{
    max-width: 120px;
  }
}

.menu__wrapper {
  position: relative;
  display: block;
}

.menu__wrapper__left {
  position: relative;
  display: block;
  float: left;
}

.menu__wrapper__main {
  position: relative;
  display: block;
  float: left;
}

.menu__wrapper__right {
  position: relative;
  display: flex;
  float: right;
  padding: 33.5px 0;
  align-items: center;
}

.menu__wrapper__lang{
  margin-right: 12px;
}

.menu__wrapper__lang span{
  font-size: 16px;
  text-transform: uppercase;
}

.menu__wrapper__lang span:before {
  position: absolute;
  top: 0px;
  bottom: 1px;
  right: -40px;
  content: "";
  background-color: rgba(var(--white-rgb), 0.15);
  width: 1px;
  margin-right: 35px;
}