.upper{
    text-transform: uppercase;
}

.icon__list li {
  list-style-type: none;
  align-items: center;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
  display: -webkit-box;
  margin-left: -25px;
}
.icon__list li:last-child {
    margin-bottom: 0;
}

.icon__list_image {
    background-color: var(--light-grey);
    width: 40px;
    height: 40px;
    padding: 10px;
    margin-right: 20px;
    border-radius: 50%;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.divider {
    height: 5px;
    width: 100%;
    background-color: transparent;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 3;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }

  .sidebar__frame {
    margin-left: 90px;
    padding: 30px;
    border: 5px solid rgb(229, 229, 229);
    border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgb(229, 229, 229) 1px, rgb(229, 229, 229) 3px) 5;
    background-color: rgb(255, 255, 255);
    min-width: 300px;
  }

  @media screen and (max-width: 992px) {
    .sidebar__frame {
      margin-left: 0;
    }
  }

.list{
  display: block;
  align-items: center; 
  gap: 8px;
}

.list li {
  list-style-type: none;
    position: relative;
    display: flex;
    align-items: baseline;
    color: rgb(0, 0, 0);
}

.listIcon {
  color: var(--cream);
  margin-right: 10px;

}

.counter {
    width: 100%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 5;
    padding: 15px;
  }
  .counter p br {
    display: none;
  }
  @media screen and (max-width: 576px) {
    .counter p br {
      display: block;
    }
  }
  .counter.mil-light {
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(255, 255, 255, 0.2) 1px, rgba(255, 255, 255, 0.2) 3px) 5;
  }
