.faq__area {
  position: relative;
  background-position: 94% 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.arrow {
  width: 25px;
  height: 25px;
  align-self: center;
}

.title {
  padding: 0px 38px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

@media (max-width: 767px) {
  .title {
    padding: 0 38px 0 20px;
  }
}
.title span {
  font-weight: 400;
  font-size: 14px;
  color: var(--cream);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 40px;
  width: 40px;
  background: #eef4fe;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .title span::before {
    right: 5px;
  }
}
@media (max-width: 767px) {
  .title span::before {
    right: 20px;
  }
}
.title span::after {
  font-weight: 400;
  font-size: 14px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background: var(--cream);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .title::after {
    right: 5px;
  }
}
@media (max-width: 767px) {
  .title::after {
    right: 20px;
  }
}

.title h4 {
  margin: 0;
  color: #0e1133;
  font-size: 18px;
  font-weight: 700;
}

.description p {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}

.inner {
  padding: 0 38px;
  padding-right: 35px;
  padding-top: 22px;
  padding-bottom: 0px;
}

@media (max-width: 767px) {
  .inner {
    padding: 0 38px 0 20px;
  }
}

.dropdown__inner {
  background-color: var(--white);
  padding: 18px 0;
  border-style: solid;
  border-width: 1px;
  border-color: #f2f4f8;
  border-radius: 0px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
}

.active {
  box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.faq {
  margin-top: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .faq {
    margin-top: 30px;
  }
}

.contact{
  color: var(--red);
}