.blog__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
  
  .blog__pagination__button{
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid var(--grey);
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    line-height: 1;
    padding: 12px 14px;
    transition: all 500ms ease;
    margin-right: 2px;
    border-radius: 3px;
  }

.active,
.blog__pagination__button:hover,
.arrows:hover {
    color: var(--grey);
    background-color: var(--cream);
}

.arrows{
    border: 1px solid var(--grey);
    margin: 6px;
    padding: 6px;
    border-radius: 3px;
    max-height: 24px;
}

@media screen and (max-width: 600px) {
  .blog__pagination__button {
    padding: 6px 8px;
  }
}