.input__frame label {
    color: rgb(0, 0, 0);
    display: block;
    font-family: "Sora", sans-serif;
    font-weight: 600;
}
.input__frame label span {
    margin-right: 5px;
}
.input__frame label .required {
    color: #e72836;
}
.input__frame input {
    width: 100%;
    height: 70px;
    font-family: "Sora", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}

.input__frame input:focus {
    outline: inherit;
    box-shadow: 0 5px 0 0 rgb(188, 255, 0);
}
.input__frame input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}
.input__frame textarea {
    padding-top: 30px;
    width: 100%;
    height: 200px;
    font-size: 15px;
    font-family: "Sora", sans-serif;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.input__frame textarea::placeholder {
    color: rgba(0, 0, 0, 0.3);
}
.input__frame textarea.mil-shortened {
    height: 200px;
}
@media screen and (max-width: 992px) {
.input__frame textarea {
    height: 200px;
    }
}
.input__frame textarea:focus {
    outline: inherit;
    border-bottom: solid 1px rgba(255, 255, 255, 0.4);
}
.input__frame.dark input, .input__frame.dark textarea {
    border-bottom: solid 1px rgb(0, 0, 0);
    color: rgb(0, 0, 0);
}
.input__frame.dark input:focus, .input__frame.dark textarea:focus {
    box-shadow: 0 5px 0 0 var(--cream);
}

.button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--cream);
    color: rgb(0, 0, 0);
    padding: 0 60px;
    height: 70px;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    font-size: 15px;
}
.button:hover {
    filter: brightness(110%);
}

.alarm{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999; 
}

.submit{
    margin: 8px!important;
    padding: 10px!important;
    height: 35px!important;
}