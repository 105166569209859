.wrapper {
  background-color: rgb(242, 242, 242);
  position: relative;
}

.upper{
  text-transform: uppercase;
}

.service__card {
  background-color: rgb(255, 255, 255);
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 60px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 5;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.service__card .card__number {
  position: absolute;
  top: 30px;
  left: 30px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: 800;
  font-size: 22px;
}

.service__card .service__text {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.service__card .button {
  position: absolute;
  bottom: -60px;
  left: calc(50% - 30px);
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
}
.service__card:hover {
  background-color: rgb(255, 255, 255);
  padding-top: 80px;
  padding-bottom: 40px;
  border: 5px solid var(--cream);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, var(--cream) 1px, var(--cream) 3px) 5;
}
.service__card:hover .service__text {
  opacity: 0;
  transform: translateY(15px) scale(0.8);
}
.service__card:hover .divider {
  background-color: var(--cream);
}
.service__card:hover .button {
  bottom: 0;
}

.icon {
  display: inline-flex;
  padding: 15px;
  width: 60px;
  height: 60px;
}

.icon__img {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.icon.icon__bg {
  background-color: var(--cream);
}

.divider {
  height: 5px;
  width: 60px;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.1) 3px) 3;
  transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  margin-left: auto;
  margin-right: auto;
}