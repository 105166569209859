.img__gallery {
  position: relative;
}

.img__gallery img {
  display: block;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 5px;
  /* width: 100%; */
  background-color: #000;
  line-height: 16px;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  font-family: sans-serif;
  -webkit-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  opacity: 0;
}

.img__gallery .img__gallery:hover .caption {
  opacity: 0.7;
}

.gallery{
  margin-bottom: 40px;
}