/*------------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body.locked {
  overflow: hidden;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid var(--black);
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 0 var(--black) inset;
  box-shadow: 0 0 0px 0 var(--black) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: varthm-btn(--oslim-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}



.blog-pagination {
  display: flex;
  align-items: center;
}

.blog-pagination a,
.blog-pagination span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #dcdce0;
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
  line-height: 1;
  padding: 12px 17px;
  transition: all 500ms ease;
}

.blog-pagination .current,
.blog-pagination a:hover {
  color: #fff;
  background-color: var(--red);
  border-color: var(--red);
}

.blog-pagination a + a,
.blog-pagination a + span,
.blog-pagination span + span,
.blog-pagination span + a {
  margin-left: 10px;
}

.swiper-pagination-styled {
  position: relative;
  bottom: auto !important;
  left: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-styled .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 2px solid var(--black);
  background-color: var(--black);
  opacity: 0.2;
  transition: all 500ms ease;
  border-radius: 50%;
}

.swiper-pagination-styled .swiper-pagination-bullet-active {
  background-color: transparent;
  border-color: var(--red);
  opacity: 1;
}

.section-title {
  margin-top: -4px;
  margin-bottom: 43px;
}

.section-title__tagline {
  position: relative;
  display: inline-block;
  color: var(--red);
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 10px;
  background-color: var(--white-grey);
  padding: 4px 20px 3px;
  border-radius: 15px;
}

.section-title__title {
  margin: 0;
  font-size: 55px;
  line-height: 65px;
  font-weight: 800;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select > .dropdown-toggle {
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu > li.selected > a {
  color: #fff;
}

.bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu > li > a:hover {
  color: #fff;
  cursor: pointer;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: var(--red);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: var(--black);
}

.scroll-to-top:hover i {
  color: #fff;
}

/* --------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 999;
  transition: all 500ms ease;
}

.main-header:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-color: rgba(var(--white-rgb), 0.1);
  z-index: -1;
}

.main-menu {
  position: relative;
  padding: 0 60px;
  z-index: 91;
}

.stricky-header.main-menu {
  padding: 0 60px;
  background-color: var(--dark-green);
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list ul {
    display: flex;
  }
}

.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
  padding-top: 36px;
  padding-bottom: 36px;
  position: relative;
}

.main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-left: 40px;
}

.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--white);
  position: relative;
  transition: all 500ms ease;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.main-menu .main-menu__list > li > a::before,
.stricky-header .main-menu__list > li > a::before {
  content: "";
  height: 1px;
  border-radius: 0px;
  background-color: var(--red);
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  transition: transform 500ms ease;
  transform: scale(0, 1);
  transform-origin: left center;
  z-index: 1;
}

.main-menu .main-menu__list > li.dropdown > a {
  display: flex;
  align-items: center;
}

.main-menu .main-menu__list > li.dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 12px;
  transition: transform 500ms ease;
  z-index: 1;
  margin-left: 8px;
}

.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.stricky-header .main-menu__list > li.current > a,
.stricky-header .main-menu__list > li:hover > a {
  color: var(--white);
}

.main-menu .main-menu__list > li.current > a::before,
.main-menu .main-menu__list > li:hover > a::before,
.stricky-header .main-menu__list > li.current > a::before,
.stricky-header .main-menu__list > li:hover > a::before {
  transform: scale(1, 1);
  transform-origin: right center;
}

.main-menu .main-menu__list > li.current > a::before {
  background-color: var(--red);
}

.main-menu-three .main-menu__list > li.current > a::before {
  background-color: #fff;
}

.main-menu .main-menu__list > li:hover > a::before {
  background-color: var(--red);
}

.main-menu-three .main-menu__list > li:hover > a::before {
  background-color: #fff;
}

.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 240px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: 500ms ease;
  z-index: 99;
  box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
}

.main-menu .main-menu__list li:hover > ul,
.stricky-header .main-menu__list li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.main-menu .main-menu__list li ul li,
.stricky-header .main-menu__list li ul li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list li ul li + li,
.stricky-header .main-menu__list li ul li + li {
  border-top: 1px solid RGBA(var(--black), 0.1);
}

.main-menu .main-menu__list li ul li a,
.stricky-header .main-menu__list li ul li a {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 30px;
  color: var(--black);
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 500ms;
}

.main-menu .main-menu__list li ul li:hover > a,
.stricky-header .main-menu__list li ul li:hover > a {
  background-color: var(--red);
  color: var(--white);
}

.main-menu .main-menu__list li ul > li:first-child > a,
.stricky-header .main-menu__list li ul > li:first-child > a {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.main-menu .main-menu__list li ul > li:last-child > a,
.stricky-header .main-menu__list li ul > li:last-child > a {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.main-menu .main-menu__list li ul li > ul,
.stricky-header .main-menu__list li ul li > ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li > ul.right-align,
.stricky-header .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu .main-menu__list li ul li > ul ul,
.stricky-header .main-menu__list li ul li > ul ul {
  display: none;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  transform: translateY(-120%);
  transition: transform 500ms ease, visibility 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--black);
  cursor: pointer;
}

.mobile-nav__buttons a + a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--red);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--white);
  cursor: pointer;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--red);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.element__anim {
  -webkit-transition: transform 0.7s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.4s linear, color 0.3s linear;
  -webkit-transition: opacity 0.4s linear, color 0.3s linear, -webkit-transform 0.7s cubic-bezier(0.3, 0, 0.3, 1);
  transition: opacity 0.4s linear, color 0.3s linear, -webkit-transform 0.7s cubic-bezier(0.3, 0, 0.3, 1);
  -o-transition: transform 0.7s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.4s linear, color 0.3s linear;
  transition: transform 0.7s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.4s linear, color 0.3s linear;
  transition: transform 0.7s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.4s linear, color 0.3s linear, -webkit-transform 0.7s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
      transform: translate(0, 20px);
  -webkit-transition-delay: 30ms;
  -o-transition-delay: 30ms;
     transition-delay: 30ms;
  opacity: 0;
}

.element__anim.animate__active,
.animate-active .element__anim,
.swiper-slide-active .element__anim {
-webkit-transform: translate(0, 0);
-ms-transform: translate(0, 0);
    transform: translate(0, 0);
opacity: 1;
}

button.mfp-close:before, button.mfp-arrow:before {
  display: none;
}

@media screen and (max-width: 992px) {
  .kf-f-hours {
    margin-bottom: 50px;
  }
}

/* image popup */
img.mfp-img {
  box-shadow: 0 0 8px rgb(0 0 0 / 60%);
  position: absolute;
  max-height: 392px;
  padding: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mfp-img-container .mfp-content {
  max-width: 400px !important;
}
.mfp-img-container .mfp-close {
  top: -110px;
  right: -24px;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  overflow: hidden;
  position: relative;
}

.main-slider .swiper-slide {
  position: relative;
  background-color: var(--black);
}

.main-slider .container {
  position: relative;
  padding-top: 270px;
  padding-bottom: 190px;
  z-index: 30;
}

.main-slider .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1);
  opacity: 0.3;
  background-color: var(--black);
  background-blend-mode: luminosity;
  transition: transform 7000ms ease, opacity 1500ms ease-in;
  z-index: 1;
}

.main-slider .swiper-slide-active .image-layer {
  transform: scale(1.15);
}

.main-slider .swiper-slide-active .image-layer {
  opacity: 0.4;
}

.main-slider-shape-1 {
  position: absolute;
  top: -290px;
  right: 430px;
  height: 770px;
  width: 360px;
  background-color: var(--black);
  mix-blend-mode: soft-light;
  border-radius: 170px;
  transform: translateY(-100%) rotate(-40deg);
  opacity: 0;
  z-index: 2;
}

.swiper-slide-active .main-slider-shape-1 {
  opacity: 1;
  transform: translateY(0) translateX(0) rotate(-40deg);
  transition: all 1000ms ease;
}

.main-slider-shape-2 {
  position: absolute;
  bottom: -172px;
  right: -59px;
  height: 560px;
  width: 260px;
  background-color: var(--red);
  mix-blend-mode: color;
  border-radius: 125px;
  transform: translateY(100%) rotate(-36deg);
  opacity: 0;
  z-index: 2;
}

.swiper-slide-active .main-slider-shape-2 {
  opacity: 1;
  transform: translateY(0) translateX(0) rotate(-36deg);
  transition: all 1000ms ease;
  transition-delay: 1500ms;
}

.swiper-slide-active .main-slider-shape-3 {
  opacity: 1;
  transform: translateY(0) translateX(0) rotate(-40deg);
  transition: all 1000ms ease;
  transition-delay: 2000ms;
}

.main-slider .swiper-slide-active h2,
.main-slider .swiper-slide-active p {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) translateX(0);
}

.main-slider__nav {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 54%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.main-slider__nav .swiper-button-next,
.main-slider__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--black);
  background-color: var(--white);
  border-radius: 20px;
  opacity: 0.2;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
}

.main-slider__nav .swiper-button-next:hover,
.main-slider__nav .swiper-button-prev:hover {
  opacity: 1;
}

.main-slider__nav .swiper-button-next::after,
.main-slider__nav .swiper-button-prev::after {
  display: none;
}

.main-slider__nav .swiper-button-prev {
  margin-bottom: 10px;
}

.main-slider__nav .swiper-button-prev .icon-left-arrow {
  position: relative;
  transform: rotate(180deg);
}

#main-slider-pagination {
  z-index: 10;
  bottom: 48px;
  left: 50%;
  width: 100%;
  max-width: 1170px;
  -webkit-transform: translate(-50%, calc(-50% + 95px));
  transform: translate(-50%, calc(0% + 0px));
  text-align: left;
}

#main-slider-pagination .swiper-pagination-bullet {
  margin: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid transparent;
  background-color: rgba(var(--white-rgb), 0.8);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

#main-slider-pagination .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 5px;
}

#main-slider-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 2px solid var(--white);
  background-color: rgba(var(--white-rgb), 0);
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes shapeMover {
  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
      translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px)
      translateX(20px);
  }
}

@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@keyframes squareMover {
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }

  20%,
  60% {
    -webkit-transform: translate(20px, 40px) rotate(180deg);
    transform: translate(20px, 40px) rotate(180deg);
  }

  30%,
  80% {
    -webkit-transform: translate(40px, 60px) rotate(0deg);
    transform: translate(40px, 60px) rotate(0deg);
  }
}

@keyframes treeMove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

/*--------------------------------------------------------------
#     Zoom Fade Css
--------------------------------------------------------------*/
.zoom-fade {
  animation-name: zoom-fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-moz-keyframes service_hexagon_2 {
  0% {
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -moz-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-o-keyframes service_hexagon_2 {
  0% {
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes service_hexagon_2 {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--dark-green);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms;
}

.mobile-nav__content .oslim-btn {
  padding: 8px 0;
  width: 100%;
  text-align: center;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  transition: 500ms;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0.1em;
}

.mobile-nav__content .main-menu__list li ul {
  margin-left: 10px;
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--red);
}

.mobile-nav__content .main-menu__list li a button {
  width: 30px;
  height: 30px;
  background-color: var(--cream);
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--black);
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--white);
  transition: 500ms;
}

.mobile-nav__social a + a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--red);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}

.mobile-nav__contact li + li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--red);
}

.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/* ===================Custom Animation================== */

@-webkit-keyframes shapeMover2 {
  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(-40deg) translateZ(0px)
      translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(-40deg) translateZ(20px)
      translateY(20px) translateX(20px);
  }
}

@keyframes shapeMover2 {
  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(-40deg) translateZ(0px)
      translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(-40deg) translateZ(20px)
      translateY(20px) translateX(20px);
  }
}

.shapemover2 {
  -webkit-animation-name: shapeMover2;
  animation-name: shapeMover2;
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes float-bob-x {
  0% {
    transform: translateX(-30px) rotate(-34deg);
  }

  50% {
    transform: translateX(-10px) rotate(-34deg);
  }

  100% {
    transform: translateX(-30px) rotate(-34deg);
  }
}

@keyframes float-bob-x {
  0% {
    transform: translateX(-30px) rotate(-34deg);
  }

  50% {
    transform: translateX(-10px) rotate(-34deg);
  }

  100% {
    transform: translateX(-30px) rotate(-34deg);
  }
}

.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-2 {
  0% {
    transform: translateY(-30px) rotate(-45deg);
  }

  50% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(-30px) rotate(-45deg);
  }
}

@keyframes float-bob-x-2 {
  0% {
    transform: translateY(-30px) rotate(-45deg);
  }

  50% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(-30px) rotate(-45deg);
  }
}

.float-bob-x-2 {
  -webkit-animation-name: float-bob-x-2;
  animation-name: float-bob-x-2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-3 {
  0% {
    transform: translateY(-30px) rotate(34deg);
  }

  50% {
    transform: translateY(-10px) rotate(34deg);
  }

  100% {
    transform: translateY(-30px) rotate(34deg);
  }
}

@keyframes float-bob-x-3 {
  0% {
    transform: translateY(-30px) rotate(34deg);
  }

  50% {
    transform: translateY(-10px) rotate(34deg);
  }

  100% {
    transform: translateY(-30px) rotate(34deg);
  }
}

.float-bob-x-3 {
  -webkit-animation-name: float-bob-x-3;
  animation-name: float-bob-x-3;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-4 {
  0% {
    transform: translateX(-30px) rotate(10deg);
  }

  50% {
    transform: translateX(-10px) rotate(10deg);
  }

  100% {
    transform: translateX(-30px) rotate(10deg);
  }
}

@keyframes float-bob-x-4 {
  0% {
    transform: translateX(-30px) rotate(10deg);
  }

  50% {
    transform: translateX(-10px) rotate(10deg);
  }

  100% {
    transform: translateX(-30px) rotate(10deg);
  }
}

.float-bob-x-4 {
  -webkit-animation-name: float-bob-x-4;
  animation-name: float-bob-x-4;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-5 {
  0% {
    transform: translateY(-30px) rotate(-37deg);
  }

  50% {
    transform: translateY(-10px) rotate(-37deg);
  }

  100% {
    transform: translateY(-30px) rotate(-37deg);
  }
}

@keyframes float-bob-x-5 {
  0% {
    transform: translateY(-30px) rotate(-37deg);
  }

  50% {
    transform: translateY(-10px) rotate(-37deg);
  }

  100% {
    transform: translateY(-30px) rotate(-37deg);
  }
}

.float-bob-x-5 {
  -webkit-animation-name: float-bob-x-5;
  animation-name: float-bob-x-5;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-6 {
  0% {
    transform: translateX(-30px) rotate(-40deg);
  }

  50% {
    transform: translateX(-10px) rotate(-40deg);
  }

  100% {
    transform: translateX(-30px) rotate(-40deg);
  }
}

@keyframes float-bob-x-6 {
  0% {
    transform: translateX(-30px) rotate(-40deg);
  }

  50% {
    transform: translateX(-10px) rotate(-40deg);
  }

  100% {
    transform: translateX(-30px) rotate(-40deg);
  }
}

.float-bob-x-6 {
  -webkit-animation-name: float-bob-x-6;
  animation-name: float-bob-x-6;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x-7 {
  0% {
    transform: translateY(-30px) rotate(-40deg);
  }

  50% {
    transform: translateY(-10px) rotate(-40deg);
  }

  100% {
    transform: translateY(-30px) rotate(-40deg);
  }
}

@keyframes float-bob-x-7 {
  0% {
    transform: translateY(-30px) rotate(-40deg);
  }

  50% {
    transform: translateY(-10px) rotate(-40deg);
  }

  100% {
    transform: translateY(-30px) rotate(-40deg);
  }
}

.float-bob-x-7 {
  -webkit-animation-name: float-bob-x-7;
  animation-name: float-bob-x-7;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
